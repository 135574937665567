.badge {
  font-size: 11px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #777;
  border-radius: 10px;
}

.badge-space {
  margin-top: 8px;
}

.badge-danger, .label-danger {
  background-color: #a22a21;
  color: #fff;
}

.badge-disable, .label-white {
  background-color: #2a2e36;
  color: #8b91a0;
}

.badge-warning, .badge-warning-light, .label-warning, .label-warning-light {
  background-color: #f1cd2b;
  color: #fff;
}